import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import Warning from "@material-ui/icons/Warning";

// @material-ui/icons
// core components
import Badge from 'components/Badge/Badge.js';

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Parallax from "components/Parallax/Parallax.js";
import SnackbarContent from "components/Snackbar/SnackbarContent.js";
import Clearfix from "components/Clearfix/Clearfix.js";
import SectionNotifications from "./Sections/SectionNotifications.js";
import SectionCarousel from "./Sections/SectionCarousel.js";
// sections for this page
import HeaderLinks from "components/Header/HeaderLinks.js";

import badgeStyle from "assets/jss/material-kit-react/components/badgeStyle.js"
import styles from "assets/jss/material-kit-react/views/components.js";

const useStyles = makeStyles(styles);


export default function Components(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (


    <div>

      <Header


        rightLinks={<HeaderLinks />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />

      <Parallax image={require("assets/img/header.gif")}>

        <div className={classes.container}>

          <GridContainer>
            <GridItem>

              <div className={classes.brand}>
                <h1 className={classes.title}></h1>
                <h3 className={classes.subtitle}>

                </h3>
              </div>
            </GridItem>
          </GridContainer>

        </div>
        <div>



      <Clearfix />
    </div>

      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>

              <SectionNotifications />

            </div>


      <Footer />
    </div>

  );
}
